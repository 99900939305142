// FormData example:
//
// {
//   "fields": [
//   {
//     "name": "Dlya_chego_vi_voshli_v_chatsymbol",
//     "type": "Text",
//     "label": "Для чего вы вошли в чат?",
//     "required": true,
//     "maxLength": null,
//     "minLength": null,
//     "description": "Ответьте на вопрос коротко",
//     "placeholder": "Введите текст"
//   }
// ],
//     "name": "Проверка новых пользователей",
//     "description": "Ответьте, пожалуйста, на несколько вопросов",
//     "guid": "123.1bfe3d5adbff41b58d44c72d27431cdb",
//     "owner_id": 353019935,
//     "chat_id": -1001443957786,
//     "open_count": 1,
//     "responce_count": 0,
//     "active": true
// }

import { BaseUserInfo } from "@/includes/types/UserProfile/types";

export type FormFieldData = {
  name: string
  type: string
  required: boolean
  label?: string
  description?: string
  placeholder?: string
}

export type FormData = {
  guid: string
  owner_id: number
  chat_id: number
  open_count: number
  responce_count: number
  active: boolean
  name: string
  description: string
  fields: Array<FormFieldData>
}

export enum FormAnswersActionTypeEnum {
  None,
  Greeting,
  Trigger,
}

export type GetFormAnswersReq = {
  chat_id: number
  key: string
  limit: number
  offset: number
}

export type FormAnswer = BaseUserInfo & {
  data: Record<string, unknown>
  action_type: FormAnswersActionTypeEnum
  created: string
}

export type GetFormAnswersRes = {
  answers: Array<FormAnswer>
}

export type GetUserFormAnswersRes = { forms: Array<FormData & GetFormAnswersRes['answers']> }
