






















































































import { FormAnswer, FormAnswersActionTypeEnum, FormData, FormFieldData } from "@/includes/logic/Forms/types";
import { InputSetups } from "@/mixins/input-setups";
import TelegramProfileButton from "@/components/TelegramProfileButton.vue";

import FileApi from "piramis-base-components/src/components/File/logic/file-api";
import ZoomableImage from 'piramis-base-components/src/components/ZoomableImage/ZoomableImage.vue';
import UserMainInfo from 'piramis-base-components/src/components/UserMainInfo.vue';
import { tgLoginLink } from "piramis-base-components/src/shared/utils/tgLoginHelpers";
import Icon from "piramis-base-components/src/components/Icon/Icon.vue";

import { Component, Mixins, Prop } from 'vue-property-decorator';
import moment from "moment";

@Component({
  data() {
    return {
      FormAnswersActionTypeEnum
    }
  },
  methods: {
    tgLoginLink
  },
  components: {
    TelegramProfileButton,
    ZoomableImage,
    UserMainInfo,
    Icon
  }
})
export default class FormAnswersItem extends Mixins(InputSetups) {
  @Prop() answer!: FormAnswer

  @Prop() formFields!: FormData['fields']

  @Prop({ type: Boolean, default: true, required: false }) showUser!: boolean

  fileApi = new FileApi()

  get answerDate() {
    return moment(this.answer.created).format('lll')
  }

  get actionTypeTitle() {
    const { action_type } = this.answer

    if (action_type === FormAnswersActionTypeEnum.Greeting) {
      return this.$t('form_answers_answer_action_type_greeting').toString()
    } else if (action_type === FormAnswersActionTypeEnum.Trigger) {
      return this.$t('form_answers_answer_action_type_trigger').toString()
    } else if (action_type === FormAnswersActionTypeEnum.None) {
      return 'None'
    } else {
      return `action_type_${ action_type }`
    }
  }

  getSaveUrl(descriptor:string):void {
    this.fileApi.downloadFile(descriptor, this.groupUploadTarget.target_id)
  }

  filePreview(descriptor:string): string {
    return this.fileApi.getFilePreviewUrl(descriptor, this.groupUploadTarget.target_id)
  }

  getAnswerValue(answerData: FormAnswer['data'], field: FormFieldData) {
    const value = answerData[field.name]

    if (field.type === 'Select') {
      if (Array.isArray(value)) {
        return value.join(', ')
      } else {
        return value
      }
    } else if (field.type === 'Checkbox') {
      return this.$t(`form_answer_field_flag_value_${ value }`)
    }

    return value
  }

  get preparedAnswers() {
    return this.formFields.map(field => {
      return {
        type: field.type,
        name: field.label,
        value: this.getAnswerValue(this.answer.data, field)
      }
    })
  }
}
