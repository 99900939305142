































import FormsApi from '@/includes/Api/Forms.api';
import { errorNotification } from "@/includes/NotificationService";
import { FormAnswer, FormData } from "@/includes/logic/Forms/types";
import FormAnswersItem from "@/components/questionnaire/components/FormAnswersItem.vue";

import DrawerWidthMixin from "piramis-base-components/src/logic/helpers/DrawerWidthMixin";

import { Component, Mixins, Prop, VModel, Watch } from 'vue-property-decorator';

@Component({
  components: {
    FormAnswersItem,
  }
})
export default class FormAnswers extends Mixins(DrawerWidthMixin, ) {
  @VModel({ type: Boolean, default: false }) isOpen!: boolean

  @Prop({ type: Object, required: true }) form!: FormData

  @Watch('isOpen')
  onChange(state: boolean) {
    if (state && this.form.responce_count > 0) {
      this.getAnswers()
    }
  }

  answers: Array<FormAnswer> = []

  page = 1

  answersLoading = false

  hasAnswers = true

  resetState() {
    this.isOpen = false
    this.page = 1
    this.answers = []
  }

  loadMore() {
    this.page += 1

    this.getAnswers()
  }

  getAnswers() {
    this.answersLoading = true

    FormsApi.getFormAnswers('tg', {
      chat_id: this.form.chat_id,
      key: this.form.guid,
      limit: 10,
      offset: this.page === 1 ? 0 : (this.page - 1) * 10
    })
      .then(({ data }) => {
        if (data.answers.length) {
          this.answers.push(...data.answers)
        }

        if (data.answers.length < 10 || !data.answers.length) {
          this.hasAnswers = false
        }
      })
      .catch(errorNotification)
      .finally(() => {
        this.answersLoading = false
      })
  }

  created() {
    this.initializeSidebarWidth(800)
  }
}
