














































































import NotAvailableOptionsOverlay from "@/components/NotAvailableOptionsOverlay.vue";
import ListItemFormCard from "@/components/questionnaire/ListItemFormCard.vue";
import FormsHelper from "../../../includes/logic/Forms/FormsHelper";
import TariffsTagsHelper from "../../../mixins/TariffsTagsHelper"
import { successNotification } from '@/includes/NotificationService'
import PermissionsBanner from "@/components/PermissionsBanner.vue";
import { SitePermissionEnum } from "@/includes/types/AddAdmin/types";
import { FormsState } from "@/store/chats/questionnaire/state";

import { UseFields } from "piramis-base-components/src/components/Pi"
import List from 'piramis-base-components/src/components/Pi/components/List/List.vue'
import { ListDirection } from 'piramis-base-components/src/components/Pi/components/List/logic/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import PageTitle from 'piramis-base-components/src/components/PageTitle.vue'
import ExtendedHelpMessage from "piramis-base-components/src/components/ExtendedHelpMessage.vue";
import { trimAndLow } from "piramis-base-components/src/shared/utils/trimAndLow";

import { Component, Mixins, Watch } from 'vue-property-decorator'

@Component({
  'components': {
    PermissionsBanner,
    List,
    ListItemFormCard,
    NotAvailableOptionsOverlay,
    PageTitle,
    ExtendedHelpMessage
  },
  methods: {
    trimAndLow
  },
  'data': () => ({
    ListDirection,
    EntityTypes,
    SitePermissionEnum
  })
})
export default class Questionnaire extends Mixins<UseFields, TariffsTagsHelper, FormsHelper>(UseFields, TariffsTagsHelper, FormsHelper) {

  @Watch('$route.params.CHAT_ID')
  onChatIdChange() {
    this.updateForms()
  }

  formsLoading = false

  get sortedForms() {
    return (this.forms ?? [])
      .slice()
      .sort((a, b) => b.responce_count - a.responce_count || b.open_count - a.open_count)
  }

  get forms(): FormsState['questionnaire']['forms'] {
    return this.$store.state.chatState.questionnaire.forms ?? []
  }

  get canChangeConfig() {
    return this.$store.getters.canChangeConfig
  }

  async deleteFormFn(guid: string): Promise<void> {
    await this.deleteForm(this.$store.getters.chatId, guid)
    await this.$store.dispatch("deleteForm", guid)

    successNotification(this.$t('success').toString())
  }

  updateForms() {
    this.formsLoading = true

    this.$store.dispatch("getForms")
      .then(() => this.formsLoading = false)
  }

  mounted() {
    this.updateForms()
  }
}
