





















































































import { FormData } from '@/includes/logic/Forms/types'
import { errorNotification } from '@/includes/NotificationService'
import FormAnswers from "@/components/questionnaire/components/FormAnswers.vue";

import { tooltipTrigger } from "piramis-base-components/src/shared/utils/tooltipTrigger";

import { Component, Prop } from 'vue-property-decorator'
import Vue from "vue";

@Component({
  components: {
    FormAnswers
  },
  methods: {
    tooltipTrigger
  }
})
export default class ListItemFormCard extends Vue {
  @Prop({ 'required': true }) readonly form!: FormData

  @Prop() readonly deleteFormFn!: () => Promise<any>

  @Prop() readonly setupFormFn!: () => Promise<any>

  @Prop() readonly watchFormFn!: () => Promise<any>

  @Prop() readonly copyFormFn!: () => Promise<any>

  @Prop({ type: Boolean, default: false, required: false }) disabled!: boolean

  formAnswersModalOpen = false

  deleteForm(): void {
    this.$confirm({
      title: this.$t("form_confirm_remove").toString(),
      content: this.$t("form_confirm_remove_text").toString(),
      okText: this.$t("pi_accept").toString(),
      cancelText: this.$t("pi_reject").toString(),
      onOk: async () => {
        try {
          await this.deleteFormFn()
        } catch (error) {
          errorNotification(error)
        }
      },
    })
  }
}
